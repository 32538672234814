import React from "react";
import {
  AppBar,
  Hidden,
  makeStyles,
  Toolbar,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";
import Link from "@material-ui/core/Link";

const appBarWidth = "69px";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: `white`,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      position: "static",
      width: appBarWidth,
      alignItems: "flex-start",
      height: "fit-content",
      borderRadius: "10px",
    },
  },
  button: {
    width: "100%",
    color: `#4b4b4b`,
    fontWeight: 500,
    fontSize: `.75rem`,
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      borderTop: "1px solid",
      "&.MuiButton-root": {
        borderRight: "1px solid",
        minWidth: 0,
      },
      "&:last-child": {
        borderWidth: "1px 0 0 0",
      },
    },
    [theme.breakpoints.up("sm")]: {
      "&.MuiButton-root": {
        borderStyle: "solid",
        borderWidth: "1px 0 0 0",
        minWidth: 0,
      },
      "&:first-child": {
        borderWidth: "0 0 0 0",
      },
    },
    "&:hover": {
      color: "#4c7753",
    },
  },
  toolbar: {
    "&.MuiToolbar-gutters": {
      padding: 0,
      justifyContent: "space-evenly",
    },
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
    },
  },
  gridButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    backgroundColor: "#ffffff",
    padding: "15px",
    borderStyle: "solid",
    borderWidth: "0 0 1px 0",
  },
  headerText: {
    color: "#4b4b4b",
    fontSize: "15px",
    fontWeight: 500,
  },
}));

export default function MenuBar({ navLinks, marginTop }) {
  const classes = useStyles();
  const history = useHistory();

return (
    <AppBar
      style={{ marginTop: marginTop }}
      position="fixed"
      className={classes.appBar}
    >
      <Hidden smUp>
        <div className={classes.header}>
          <Link
            underline="none"
            cursor="pointer"
            onClick={() => history.push("/")}
          >
            <Typography className={classes.headerText}>Jeff Sutton</Typography>
          </Link>
        </div>
      </Hidden>
      <Toolbar component="nav" className={classes.toolbar}>
        {navLinks.map((item) => (
          <Button
            key={item.title}
            className={classes.button}
            onClick={() => history.push(item.path)}
          >
            <Grid className={classes.gridButton}>
              <item.menuIcon />
              {item.title}
            </Grid>
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
}
