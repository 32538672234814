// import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topicHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    borderStyle: "solid",
    borderBottomWidth: "1px",
    borderImage:
      "linear-gradient(to right,rgba(0,0,0,1) 0%, #7c9082 0%,rgba(0,0,0,0) 100%) 0 0 1 0 stretch",
  },
  dot: {
    height: "40px",
    width: "40px",
    backgroundImage: "linear-gradient(to right bottom, #c8d5ca, #eff3f0)",
    borderRadius: "50%",
    margin: "10px 0 0 0",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    marginTop: "5px",
    marginLeft: "-25px",
  },
}));

export default function TopicHeader({ titleText }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.topicHeader}>
        <span className={classes.dot} />
        <span className={classes.title}>{titleText}</span>
      </div>
    </>
  );
}
