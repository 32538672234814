import React from "react";
import TopicHeader from "../components/TopicHeader";
import { Typography } from "@material-ui/core";
import { useStyles } from "../styles/styles";

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <TopicHeader titleText="Things I Built" />
      <Typography className={classes.paragraph}>
        Other than this site, nothing yet.
      </Typography>
      <br/>
      <TopicHeader titleText="Things I'm Building" />
      <Typography className={classes.paragraph}>
        Whole bunch of stuff started.  One day I'll need to finish some of it.
      </Typography>
     </div>
  );
}
