import { GridList, GridListTile, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    paddingLeft: "10px",
  },
  gridListTile: {
    marginTop: "10px",
  },
  date: {
    margin: "10px 0 10px 0",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#4c7753",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "0 5px 0 5px",
    display: "inline-block",
  },
  title: {
    fontSize: "17px",
    color: "#323232",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "14px",
    color: "#999",
    fontWeight: 400,
  },
  body: {
    margin: "10px 15px 10px 25px",
    fontSize: "15px",
    lineHeight: "1.8em",
    padding: 0,
  },
  divider: {
    marginTop: "10px",
    borderStyle: "solid",
    borderBottomWidth: "10px",
    borderImage:
      "linear-gradient(to left, rgba(0, 0, 0, 0) 1%, #7c9082 50%, rgba(0, 0, 0, 0) 100%) 0 0 100% 0/1px 0 1px 0 stretch",
  },
  imageContainer: {
    marginRight: "5px",
    marginLeft: "5px",
    width: "90px",
    height: "90px",
    float: "right",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default function InfoTile({ numColumns, tiles }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList
        cols={numColumns}
        className={classes.gridList}
        cellHeight={"auto"}
      >
        {tiles.map((tile, index) => (
          <GridListTile id={index} key={index} className={classes.gridListTile}>
            {tile.date !== "" ? (
              <Typography className={classes.date}>{tile.date}</Typography>
            ) : null}
            <div className={classes.imageContainer}>
              <img className={classes.image} src={`${tile.imagePath}`} alt="" />
            </div>
            <Typography className={classes.title}>{tile.title}</Typography>
            <Typography className={classes.subTitle}>{tile.company}</Typography>
            {tile.body.map((text, index) => (
              <Typography key={index} className={classes.body}>{text}</Typography>
            ))}
            {index + 1 !== tiles.length ? (
              <div className={classes.divider} />
            ) : null}
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
