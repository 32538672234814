import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  ultraDense: {
    "&.MuiListItem-root": {
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  iconFormat: {
    "&.MuiListItemIcon-root": {
      minWidth: '35px',
    },
  },
  listItemText: {
    fontSize:'15px',
  },
}));

export default function CheckedList({ numColumns, items, icon }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.demo}>
        <List>
          {items.map((text, index) => (
            <ListItem key={index} className={classes.ultraDense}>
              <ListItemIcon className={classes.iconFormat}>
                <Icon>{icon}</Icon>
              </ListItemIcon>
              <ListItemText classes={{primary:classes.listItemText}} primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
