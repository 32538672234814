import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider, useMediaQuery } from "@material-ui/core";
import Layout from "./components/Layout";
import Home from "./pages/Landing";
import About from "./pages/About";
import Resume from "./pages/Resume";
import Works from "./pages/Works";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import BrushOutlined from "@material-ui/icons/BrushOutlined";
import AlternateEmailOutlined from "@material-ui/icons/AlternateEmailOutlined";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import ChatOutlined from "@material-ui/icons/ChatOutlined";
import HomeOutlined from "@material-ui/icons/HomeOutlined";

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          background:
            "linear-gradient(to bottom right,#85898a 0%,#e5e6e8 100%)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightLight: 400,
    fontWeightLRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

const menuItems = [
  {
    title: "Home",
    path: "/",
    menuIcon: HomeOutlined,
    component: Home,
  },
  {
    title: "About",
    path: "/about",
    menuIcon: InfoOutlined,
    component: About,
  },
  {
    title: "Resume",
    path: "/resume",
    menuIcon: DescriptionOutlined,
    component: Resume,
  },
  {
    title: "Works",
    path: "/works",
    menuIcon: BrushOutlined,
    component: Works,
  },
  {
    title: "Blog",
    path: "/blog",
    menuIcon: ChatOutlined,
    component: Blog,
  },
  {
    title: "Contact",
    path: "/contact",
    menuIcon: AlternateEmailOutlined,
    component: Contact,
  },
];

function App() {
  const xsDnScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const smUpScreen = useMediaQuery(theme.breakpoints.up("md"));
  const navLinks = (xsDnScreen || smUpScreen) ? menuItems.slice(1) : menuItems;
  const routeComponents = 
    (xsDnScreen) ? 
      menuItems.map(({path, component}, key) => 
        <Route exact path={path} component={component} key={key} />)
      :
      navLinks.map(({path, component}, key) => 
        <Route exact path={path} component={component} key={key} />);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout props navLinks={navLinks} LandingPage={Home} defaultContent='/about'>
          <Switch>
            {routeComponents}
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
