import { Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    display: "flex",
    alignItems: "center",
    borderStyle: "solid",
    borderBottomWidth: "10px",
    borderImage:
      "linear-gradient(to left, rgba(0, 0, 0, 0) 1%, #7c9082 50%, rgba(0, 0, 0, 0) 100%) 0 0 100% 0/1px 0 1px 0 stretch",
  },
  dot: {
    height: "40px",
    width: "40px",
    backgroundImage: "linear-gradient(to right bottom, #c8d5ca, #eff3f0)",
    borderRadius: "50%",
    margin: "20px 0 0 10px",
  },
  headerTitle: {
    fontSize: "17px",
    fontWeight: 500,
    marginTop: "0px",
    marginLeft: "0px",
  },
}));

export default function ColumnHeader({ titleText, iconName, textColor }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.columnHeader}>
        <Icon style={{ fontSize: 40, color: '#4c7753' }}>{iconName}}</Icon>
        <span style={{color: textColor}} className={classes.headerTitle}>{titleText}</span>
      </div>
    </>
  );
}
