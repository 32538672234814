import React from "react";
import TopicHeader from "../components/TopicHeader";
import InfoTile from "../components/InfoTile";
import { Typography } from "@material-ui/core";
import { useStyles } from "../styles/styles";

const aboutBody = [
  {
    paragraph:
      "Hello! I’m Jeff Sutton. Back-end & Front-end developer from Cornelius, NC. I have rich experience in PL/SQL, vendor management and systems management.",
  },
  {
    paragraph:
      "I'm a proven technical leader with expertise in bridging the gap between business and technical teams by translating business requirements into technical requirements.",
  },
  {
    paragraph:
      "A very technically diverse background in several development stacks, with an emphasis on Oracle PL/SQL development.",
  },
];

const tiles = [
  {
    date: "",
    title: "AWS Certified Solutions Architect Associate",
    company: "  ",
    imagePath: "images/AWS-SolArchitect-Associate-2020.png",
    icon: "cloud",
    body: [
      "In February 2021, I passed the AWS Certified Solutions Architect Associate exam.",
      "The AWS Certified Solutions Architect - Associate certification validates the ability to design and deploy well-architected solutions on AWS that meet customer requirements.",
    ],
  },
];

export default function About() {
  const classes = useStyles();
  const colCount = tiles.length > 1 ? 2 : 1;

  return (
    <div className={classes.card}>
      <TopicHeader titleText="About Me" />
      {aboutBody.map((textItem, index) => (
        <Typography key={index} className={classes.paragraph}>
          {textItem.paragraph}
        </Typography>
      ))}
      <br />
      <TopicHeader titleText="Certifications / Training" />
      <InfoTile numColumns={colCount} tiles={tiles} />
    </div>
  );
}
