import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CssBaseline, Hidden, useMediaQuery } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import MenuBar from "./MenuBar";
import { Redirect } from "react-router";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  appbar: theme.mixins.appbar,
  headerPadding: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "110px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "50px",
    },
  },
}));

export default function Layout({ children, navLinks, LandingPage, defaultContent }) {
  const classes = useStyles();
  const theme = useTheme();
  const xsDnScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const menuBarPaddingTop = xsDnScreen ? "0px" : "35px";
  const history = useHistory();
  const path = (history.location.pathname === '/') ? defaultContent : history.location.pathname;

return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={12}>
        <div id="headerPadding" className={classes.headerPadding} />
        <Grid container justify="center" wrap="nowrap">
          <Grid item>
            <MenuBar navLinks={navLinks} marginTop={menuBarPaddingTop} />
          </Grid>
          <Hidden smDown>
            <Grid item md={4} lg={3} xl={2}>
              <LandingPage />
              <Redirect to={path} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6} md={6} lg={5} xl={3}>{children}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
