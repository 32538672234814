import React from "react";
import ColumnHeader from "../components/ColumnHeader";
import { Grid } from "@material-ui/core";
import TopicHeader from "../components/TopicHeader";
import InfoTile from "../components/InfoTile";
import CheckedList from "../components/CheckedList";
import { useStyles } from "../styles/styles";

const resumeItems = [
  {
    date: "2003 - Present",
    title: "Senior Software Engineer",
    company: "Wells Fargo",
    imagePath: "images/Wells_Fargo_Logo.png",
    body: ["Tasked with day-to-day mangement of three vendor applications.  Responsibilities include release planning, defect remediation and application security."],
  },
  {
    date: "1995 - 2003",
    title: "Senior Consultant",
    company: "ATS / Acentron",
    imagePath: "",
    body: ["Consulted with major clients such as Duke Energy and First Union National Bank (now part of Wells Fargo).  Worked primarily with SQL Server and Oracle to develop reporting and document custody applications."],
  },
  {
    date: "1988 - 1995",
    title: "Programmer / Analyst IV",
    company: "Sprint / United Telephone",
    imagePath: "images/sprint_logo.jpg",
    body: ["Designed and developed a Smalltalk/V application that front-ended an aging mainframe-based order entry application."],
  },
];

const educationItems = [
  {
    date: "1984 - 1988",
    title: "Indiana-Purdue University",
    company: "Fort Wayne, IN",
    imagePath: "/images/IPFW_Logo.png",
    body: ["Bachelor of Applied Science – Computer Science"],
  },
];

const knowledgeItems = [
  "Business Relationship Management",
  "Business Systems Analysis", 
  "Technical Leader", 
  "Vendor Relationship Management", 
  "Product Management",
];

const codingItems = [
  "PL/SQL",
  "React", 
  "Material UI", 
  "C#", 
  "HTML",
  "CSS",
];

export default function Resume() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <TopicHeader titleText="Resume" />
      <Grid container className={classes.resumeContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.columnDivider}>
          <ColumnHeader
            titleText="Experience"
            iconName="account_balance"
            textColor="#565656"
          />
          <InfoTile numColumns={1} tiles={resumeItems} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.transparentDivider}>
          <ColumnHeader
            titleText="Education"
            iconName="business_center"
            textColor="#565656"
          />
          <InfoTile numColumns={1} tiles={educationItems} />
        </Grid>
      </Grid>
      <br />
      <TopicHeader titleText="Skills" />
      <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={6} lg={6} className={classes.columnDivider}>
          <ColumnHeader
            titleText="Specialties"
            iconName="list"
            textColor="#565656"
          />
          <CheckedList numColumns={1} items={knowledgeItems} icon="check" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.transparentDivider}>
          <ColumnHeader
            titleText="Coding"
            iconName="code"
            textColor="#565656"
          />
          <CheckedList numColumns={1} items={codingItems} icon="check" />
        </Grid>
      </Grid>
    </div>
  );
}
