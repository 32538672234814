import React from "react";
import TopicHeader from "../components/TopicHeader";
import { Typography } from "@material-ui/core";
import { useStyles } from "../styles/styles";

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <TopicHeader titleText="Contact Me" />
      <Typography className={classes.paragraph}>
        <a href="mailto:jsuttonnc@icloud.com">Jeff Sutton</a>
      </Typography>
      <br />
      <TopicHeader titleText="Contact Form" />
      <Typography className={classes.paragraph}>
        Perhaps I'll add a contact form also.
      </Typography>
     </div>
  );
}