import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    height: "80vh",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    overflow: "scroll",
    padding: "10px",
    overflowX: "hidden",
    [theme.breakpoints.down("xs")]: {
       height: "83vh",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      margin: "10px 0 0 8px",
      boxShadow: "-9px -8px 0px -2px #7C9082",
    },
    [theme.breakpoints.up("md")]: {
      height: "75vh",
      marginTop: "40px",
      marginLeft: '15px',
    },
  },
  columnDivider: {
    borderStyle: "solid",
    borderImage:
      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, #7c9082 50%, rgba(0, 0, 0, 0) 100%) 0 100% 0 0/0 1px 0 0 stretch",
    [theme.breakpoints.down("sm")]: {
      border: "solid transparent",
    },
  },
  transparentDivider: {
    border: "solid transparent",
  },
  resumeContainer: {
    flexGrow: 0,
    paddingTop: "10px",
  },
  paragraph: {
    margin: "10px 15px 10px 15px",
  },
  footer: {
    bottom: '0px',
    position: 'absolute',
    width: '-webkit-fill-available',
    fontSize: 14,
    textAlign: "center",
    borderStyle: "solid",
    borderWidth: "3px",
    borderImage:
      "linear-gradient(to left, rgba(0, 0, 0, 0) 1%, #7c9082 50%, rgba(0, 0, 0, 0) 100%) 100% 0 0 0/3px 0 3px 0 stretch",
  },
  button: {
    color: "#323232",
    "&:hover": {
      color: "#4c7753",
      backgroundColor: "#ffffff",
    },
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 1px #7C9082'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      outline: '1px solid slategrey'
    }
  }
}))
export {useStyles}