import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import { Button, Typography } from "@material-ui/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyles } from "../styles/styles";

const componentStyles = makeStyles((theme) => ({
  homeCard: {
    position: 'relative',
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      margin: "10px 0 0 8px",
      boxShadow: "-9px -8px 0px -2px #7C9082",
    },
    [theme.breakpoints.up("md")]: {
      height: "80vh",
      marginTop: "25px",
      marginLeft: '8px',
    },
  },
}));

export default function Landing() {
  const classes = useStyles();
  const componentClasses = componentStyles();

  return (
    <div className={classes.card+' '+componentClasses.homeCard}>
      <Typography gutterBottom variant="h4" component="h2">
        Jeff Sutton
      </Typography>
      <Typography variant="body1" color="textSecondary" component="p">
        Proven technical leader with expertise in bridging the gap between
        business and technical teams by translating business requirements into
        technical requirements.
      </Typography>
      <br />
      <Typography variant="body1" color="textSecondary" component="p">
        I have a very technically diverse background in several development
        stacks, with an emphasis on Oracle PL/SQL development.
      </Typography>
      <div className={classes.footer}>
        <Button className={classes.button} endIcon={<CloudDownloadOutlined />}>
          Download Resume
        </Button>
      </div>
    </div>
  );
}
