import React from "react";
import TopicHeader from "../components/TopicHeader";
import { Typography } from "@material-ui/core";
import { useStyles } from "../styles/styles";

export default function About() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <TopicHeader titleText="Blog" />
      <Typography className={classes.paragraph}>
        My wife says that blogging is the last thing I should be doing :)
      </Typography>
     </div>
  );
}